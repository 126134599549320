<template>
  <div class="post">
    <!--<NavbarComponent />-->
    <div class="columns mb-0" >
      <div class="column is-one-quarter p-2" >
        <!--<TipComponent size="80%" />-->
        <div class="card m-4" style="border:1px solid #dddddd;box-shadow:none;">
          <div class="card-image">
            <figure class="image is-4by3">
              <img
                src="https://assets.unlayer.com/projects/0/1715397391127-idea_born.png"
                alt="idea background"
              />
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img
                    src="https://edwinariza.com/static/avatars/10.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-4">Edwin Ariza</p>
                <p class="subtitle is-6">@edwin_ariza</p>
              </div>
            </div>
            <div class="content">
              Transform your idea in a cool business to improve your life.
              <div style="padding-top:5px;border-top: 2px solid #dddddd;">
                <a target="_blank" href="https://x.com/edwin_ariza">
                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 509.64"><rect width="512" height="509.64" rx="115.61" ry="115.61"/><path fill="#fff" fill-rule="nonzero" d="M323.74 148.35h36.12l-78.91 90.2 92.83 122.73h-72.69l-56.93-74.43-65.15 74.43h-36.14l84.4-96.47-89.05-116.46h74.53l51.46 68.04 59.53-68.04zm-12.68 191.31h20.02l-129.2-170.82H180.4l130.66 170.82z"/></svg>
                </a>
              </div>
              <!--<a href="#">#css</a> <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>-->
            </div>
          </div>
        </div>
      </div>
      <div class="column is-three-quarters">
        <PostContentComponent class="mt-5" />
      </div>
    </div>
    <PostsComponent />
  </div>
</template>

<script>
//import NavbarComponent from "@/components/NavBar/NavBarComponent.vue";
// import BannerComponent from "@/components/Banner/BannerComponent.vue";
// import StepsComponent from "@/components/Steps/StepsComponent.vue";
// import PostsComponent from "@/components/Posts/PostsComponent.vue";
// import Topics from '../../../edwinariza.ui/components/sections/topics.vue'
import PostContentComponent from "@/components/Posts/PostContentComponent.vue";
import PostsComponent from "@/components/Posts/PostsComponent.vue";
//import TipComponent from "@/components/Tips/TipComponent.vue";
// import FaqComponent from "@/components/Faq/FaqComponent.vue";
// import SkillsComponent from "@/components/Skills/SkillsComponent.vue";
// import TipComponent from "@/components/Tips/TipComponent.vue";
export default {
  name: 'PostsView',
  components:{
    //TipComponent,
    PostsComponent,
    //NavbarComponent,
    PostContentComponent
  }
}
</script>
<style lang="scss" scoped>
  .post{
    .columns{
      .columns {
        .card{
          box-shadow: none;
          border: 2px solid #dddddd;
        }
      }
    }
  }
</style>
