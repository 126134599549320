<template>
  <section  id="faq" class="faq pb-5">
    <div class="faq-content p-2">
      <h2 class="title">Frequently Asked Questions</h2>
      <b-collapse
          class="card"
          animation="slide"
          v-for="(collapse, index) of collapses"
          :key="index"
          :open="isOpen == index"
          @open="isOpen = index"
          :aria-id="'contentIdForA11y5-' + index">
        <template #trigger="props">
          <div
              class="card-header mt-2"
              role="button"
              :aria-controls="'contentIdForA11y5-' + index"
              :aria-expanded="props.open"
          >
            <p class="card-header-title">
              {{ collapse.question }}
            </p>
            <a class="card-header-icon">
              <b-icon
                  :icon="props.open ? 'menu-down' : 'menu-up'">
              </b-icon>
            </a>
          </div>
        </template>
        <div class="card-content mb-2">
          <div class="content" v-html="collapse.answer"></div>
        </div>
      </b-collapse>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: 'FaqComponent',
  data() {
    return {
      isOpen: 0,
      collapses: []
    }
  },
  methods: {
    popularFaqs: function(){
      axios.get('https://edwinariza.com/faqs/get').then(response => {
        this.collapses = response.data;
        this.isLoading = false;
      });
    },
  },
  mounted() {
    this.popularFaqs()
  }
}
</script>
<style lang="scss" scoped>
  @import "../../assets/main.scss";
  .faq{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #222222;
    .faq-content{
      width: 80%;
      .title{
        font-size: 2em;
        text-align: center;
        color: #fefefe;
      }
      .card-header-title{
        font-size: 1.5em;
      }
    }
  }

</style>