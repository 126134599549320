<template>
  <div class="home_view">
    <BannerComponent title="IT Project Manager"
                     subtitle="Leading successful IT projects and teams to create cool and smart real solutions."
                     :image="require('@/assets/it_project.svg')"
                     background="#f8f8f8"
                     color="#222222"
    />
    <BriefComponent @open-modal-contact="$emit('open-modal-contact')" title="Do you need an IT Project Manager?" subtitle="I really love the project management, each day is a challenge." content="" />
    <!--<NavLanding />-->
    <CertificationsComponent />
    <IamComponent />
    <!--<DeveloperBriefComponent />-->
    <!--<StepsComponent />-->
    <!--<BriefComponent />-->
    <!-- STARTUPS -->
    <!--<SystemicoBannerComponent />-->
    <!--<WydeaLabBannerComponent />-->
    <!--<PostsScrapidtComponent />-->
    <!--<JumichicaBannerComponent />-->
    <!--<ExperienceComponent /> -->
    <!--<PostsGithubComponent />-->
    <!--<div class="columns m-0 p-0">
      <div class="column">
        <SkillsComponent />
      </div>
      <div class="column">
        <TipComponent />
      </div>
    </div>-->
    <!-- SERVICES-->
    <!--<ProductsOptionsComponent class="has-background-grey-lighter p-3" id="pricing" />-->
    <FilesComponent title="Formats and How-to's"
                    subtitle="Project manager's formats for different situations and needs."
                    :items="this.files"
    />
    <AdsComponent
        title="<b>Grumpyturtle</b>, a project manager platform for your company."
        subtitle="Improve your projects and team with a simple and cool solution."
        note="I participated like a <b>project manager of grumpyturtle platform</b> the software linked to this recommendation."
        :tags="tags"
        background="#FFC733"
        image="ads/image_1.svg"
        url="https://systemico.net/products/grumpyturtle"
    />
    <PostsComponent />
    <!--<FaqComponent />-->
  </div>
</template>

<script>
import BannerComponent from "@/components/Banner/BannerComponent.vue";
//import StepsComponent from "@/components/Steps/StepsComponent.vue";
import PostsComponent from "@/components/Posts/PostsComponent.vue";
//import FaqComponent from "@/components/Faq/FaqComponent.vue";
//import SkillsComponent from "@/components/Skills/SkillsComponent.vue";
//import TipComponent from "@/components/Tips/TipComponent.vue";
//import SystemicoBannerComponent from "@/components/Services/SystemicoBannerComponent.vue";
//import WydeaLabBannerComponent from "@/components/Services/WydeaBannerComponent.vue";
//import JumichicaBannerComponent from "@/components/Services/JumichicaBannerComponent.vue";
//import ProductsOptionsComponent from "@/components/Paypal/ProductsOptionsComponent.vue";

//import BriefComponent from "@/components/Intro/BriefComponent.vue";
//import PostsScrapidtComponent from "@/components/Posts/PostsScrapidtComponent.vue";
import CertificationsComponent from "@/components/Content/CertificationsComponent.vue";
import IamComponent from '@/components/Intro/IamComponent.vue';
//import NavLanding from "@/components/NavBar/NavLanding.vue";
//import ExperienceComponent from "@/components/Intro/ExperienceComponent.vue";
import BriefComponent from "@/components/Intro/BriefComponent.vue";
import FilesComponent from "@/components/Content/FilesComponent.vue";
import AdsComponent from "@/components/Banner/AdsComponent.vue";

export default {
  name: 'ProjectManagerView',
  components:{
    AdsComponent,
    FilesComponent,
    BriefComponent,
    //ExperienceComponent,
    //NavLanding,
    CertificationsComponent,
    //BriefComponent,
    //ProductsOptionsComponent,
    //JumichicaBannerComponent,
    //WydeaLabBannerComponent,
    //SystemicoBannerComponent,
    //SkillsComponent,
    //FaqComponent,
    PostsComponent,
    //StepsComponent,
    BannerComponent,
    //TipComponent,
    //PostsScrapidtComponent,
    IamComponent
  },
  metaInfo: {
    title: 'Edwin Ariza: IT Project Manager.',
    // override the parent template and just use the above title only
    titleTemplate: null
  },
  data(){
    return {
      tags: [
        {id:1, name:"recommendation", type: "dark"},
        {id:2, name:"software", type: "white"}
      ],
      files: [
        /**{
          id: 1,
          name: 'Requirements format.',
          description:'Define requirements clearly with the correct and required information.',
          url: '/static/files/file_1.pdf',
          image: 'icons/document.svg',
          type: 'format'
        },**/
        {
          id: 2,
          name: 'Project Charter.',
          description:'Define requirements clearly with the correct and required information.',
          url: 'https://docs.google.com/document/d/e/2PACX-1vSQvY9vu1YQKPCnmm11YK46pQjBofRSz5JH2x8M5aPeBJ_BZVoO18cx6BOfTDd2zLyCtsRuHvJaD1uo/pub',
          image: 'icons/document.svg',
          type: 'format'
        },
        /**{
          id: 3,
          name: 'Project Plan.',
          description:'Define requirements clearly with the correct and required information.',
          url: '/static/files/file_1.pdf',
          image: 'icons/document.svg',
          type: 'format'
        },
        {
          id: 4,
          name: 'User Story.',
          description:'Define requirements clearly with the correct and required information.',
          url: '/static/files/file_1.pdf',
          image: 'icons/document.svg',
          type: 'format'
        },
        {
          id: 5,
          name: 'Meeting Control.',
          description:'Define requirements clearly with the correct and required information.',
          url: '/static/files/file_1.pdf',
          image: 'icons/document.svg',
          type: 'format'
        },
        {
          id: 6,
          name: 'Changes management.',
          description:'Define requirements clearly with the correct and required information.',
          url: '/static/files/file_1.pdf',
          image: 'icons/document.svg',
          type: 'format'
        }**/
      ]
    }
  }
}
</script>
<style lang="scss">
*{
  font-family:"Quicksand", sans-serif;
}
.home_view{
  background: #fefefe;
}
</style>