<template>
  <section class="iam" id="iam">
    <div class="iam-content">
      <h2>Who I am?</h2>
      <div class="columns mt-5">
        <div class="column is-4">
          <img src="@/assets/iam.svg" alt="Who I am" style="width: 100%;"/>
        </div>
        <div class="column is-4">
          <p class="text-left">
            <b>I'm a Consultant and Entrepreneur with focus on technologies and disruptions innovations.</b>
            WEPOWER Alumni 2022 (Saint Louis, MO), Certified Project manager (PMP & SCRUM), Senior Software Architect and Agritech Researcher.
          </p><br>
          <p class="text-left">
            The technology and help to solve common problems is my passion, <b>i invest my time leading, researching, developing,
            and testing solutions to share ideas, mistakes and knowledge</b> to improve the project management and the innovation impact in different organizations types.
          </p>
        </div>
        <div class="column is-4">
          <b>
            My goal</b> right now <b>is develop smart, automatic and innovate solutions for business, using the computer vision on AgriTech solutions and
            Integrate people to develop technologies using the Citizen Development (No-Code Solutions).
          </b>
        </div>
      </div>
      <div class="text-center" style="text-align: center">
        <a href="https://www.linkedin.com/in/edwinariza/" style="font-size: 1.2rem" title="LinkedIn" target="_blank" class="button has-background-primary is-dark is-rounded">
          <svg-icon class="pt-2" type="mdi" :path="linkedin"></svg-icon> LinkedIn Profile</a>
      </div>
    </div>
  </section>
</template>
<script>
  import SvgIcon from "@jamescoyle/vue-icon";
  import { mdiLinkedin } from '@mdi/js';
  export default {
    name: 'IamComponent',
    components: {SvgIcon},
    data: function () {
      return {
        linkedin: mdiLinkedin
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../assets/main.scss";
  .iam{
    border-bottom: 2px solid #222222;
    padding:3em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fefefe;
    .iam-content{
      max-width: $page_content_width;
      h2{
      font-size: 2em;
      color: #222222;
      font-weight: bold;
      text-align: center;
      }
      h4{
        font-size:1.3em;
        color:#333333;
        margin-right: 15%;
        margin-left: 15%;
      }
      div{
        font-size:1.1em;
        color:#444444;
      }
    }
  }
</style>