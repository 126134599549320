<template>
  <div class="card m-1">
    <div class="card-image">
      <figure class="image is-4by3">
        <img :src="$props.image" alt="Placeholder image">
      </figure>
    </div>
    <div class="card-content">
      <p class="title">
        <a :href="$props.url">{{$props.name}}</a>
      </p>
      <div v-html="$props.description"></div>
      <div class="content">
      </div>
    </div>
    <footer class="card-footer">
      <!-- NOTHING FOR NOW -->
    </footer>
  </div>
</template>

<script>
export default {
  name: "PostResearchComponent",
  props: ['name', 'description', 'author', 'image', 'url'],
  setup(props) {
    console.log(props)
  },
  data() {
    return {

    }
  }
}
</script>