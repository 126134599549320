<template>
  <div class="home_view">
    <BannerComponent title="IT Project Manager"
                     subtitle="Leading successful it projects and teams to create cool and smart real solutions."
                     :image="require('@/assets/developer.svg')"
                     background="#f8f8f8"
                     color="#222222"
    />
    <!--<NavLanding />-->
    <!--<IamComponent />-->
    <DeveloperBriefComponent />
    <!--<StepsComponent />-->
    <!--<BriefComponent />-->
    <!-- STARTUPS -->
    <!--<SystemicoBannerComponent />-->
    <!--<WydeaLabBannerComponent />-->
    <!--<PostsScrapidtComponent />-->
    <!--<JumichicaBannerComponent />-->
    <!--<ExperienceComponent />-->
    <!--<PostsGithubComponent />-->
    <!--<div class="columns m-0 p-0">
      <div class="column">
        <SkillsComponent />
      </div>
      <div class="column">
        <TipComponent />
      </div>
    </div>-->
    <!-- SERVICES-->
    <!--<ProductsOptionsComponent class="has-background-grey-lighter p-3" id="pricing" />-->
    <!--<CertificationsComponent />-->
    <PostsComponent />
    <!--<FaqComponent />-->
  </div>
</template>

<script>
import BannerComponent from "@/components/Banner/BannerComponent.vue";
//import StepsComponent from "@/components/Steps/StepsComponent.vue";
import PostsComponent from "@/components/Posts/PostsComponent.vue";
//import FaqComponent from "@/components/Faq/FaqComponent.vue";
//import SkillsComponent from "@/components/Skills/SkillsComponent.vue";
//import TipComponent from "@/components/Tips/TipComponent.vue";
//import SystemicoBannerComponent from "@/components/Services/SystemicoBannerComponent.vue";
//import WydeaLabBannerComponent from "@/components/Services/WydeaBannerComponent.vue";
//import JumichicaBannerComponent from "@/components/Services/JumichicaBannerComponent.vue";
//import ProductsOptionsComponent from "@/components/Paypal/ProductsOptionsComponent.vue";
//import BriefComponent from "@/components/Intro/BriefComponent.vue";
//import PostsScrapidtComponent from "@/components/Posts/PostsScrapidtComponent.vue";
//import CertificationsComponent from "@/components/Content/CertificationsComponent.vue";
//import IamComponent from '@/components/Intro/IamComponent.vue';
//import NavLanding from "@/components/NavBar/NavLanding.vue";
//import ExperienceComponent from "@/components/Intro/ExperienceComponent.vue";
import DeveloperBriefComponent from '@/components/Intro/DeveloperBriefComponent.vue';

export default {
  name: 'EntrepreneurView',
  components:{
    //ExperienceComponent,
    //NavLanding,
    //CertificationsComponent,
    //BriefComponent,
    //ProductsOptionsComponent,
    //JumichicaBannerComponent,
    //WydeaLabBannerComponent,
    //SystemicoBannerComponent,
    //SkillsComponent,
    //FaqComponent,
    PostsComponent,
    //StepsComponent,
    BannerComponent,
    //TipComponent,
    DeveloperBriefComponent
    //PostsScrapidtComponent,
    //IamComponent
  },
  metaInfo: {
    title: 'Edwin Ariza: Entrepreneur.',
    // override the parent template and just use the above title only
    titleTemplate: null
  },
}
</script>
<style lang="scss">
*{
  font-family:"Quicksand", sans-serif;
}
.home_view{
  background: #fefefe;
}
</style>