<template>
  <section class="hero pb-5" :style="'background-color:'+background">
    <div class="hero-body container-fluid mb-1" >
      <div class="columns">
        <div class="column col-7">
          <b-taglist attached>
            <b-tag v-for="tag in $props.tags" :type="'is-'+tag.type"  :key="tag.id">
              <b v-if="tag.id === 1">{{tag.name}}</b>
              <span v-else>{{tag.name}}</span>
            </b-tag>
          </b-taglist>
          <p class="title" :style="'color:'+color" v-html="title"></p>
          <p class="subtitle" :style="'color:'+color"  v-html="$props.subtitle"></p>

          <div>
            <a class="button is-secondary is-rounded" target="_blank" :href="$props.url"><b>Get a free account</b></a>
            <div class="note is-small" v-html="$props.note"></div>
          </div>
        </div>
        <div class="column col-5 is-hidden-mobile mt-5 is-center">
          <img class="align-self-center" :src="require('@/assets/'+$props.image)"  style="height: 400px;" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    export default {
        name: 'AdsComponent',
        components: {
        },
        props: ['title', 'subtitle', 'image', 'showButtons', 'background', 'color', 'tags', 'url', 'note'],
        setup(props) {
          console.log(props.title)
          console.log(props.subtitle)
          console.log(props.image)
        },
    };
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss";

  .hero{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero-body{
    max-width: $page_content_width;
  }
  .title{
    font-size: 3em;
  }
  .subtitle{
    font-size: 2em;
    margin-bottom: 4vh;
  }

  @media only screen and (max-width: 480px){
    .hero{
      min-height: 60vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .hero-body{
      max-width: $page_content_width;
    }
    .title{
      font-size: 2.0em;
      margin-top: 1vh;
    }
    .subtitle{
      font-size: 1.1em;
    }
    .note{
      font-size: 0.8em;
      color:#555555;
    }
  }
</style>