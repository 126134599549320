<template>
  <div>
    <div class="content ">
      <h1 class="has-text-success">Successful Payments.</h1>
      <h2 class="message_success">
        Thanks for your payment, I'm going to contact you as soon as posible.
      </h2>
      <h3>
        Please contact me if you need assistance or any help.<br>
        <a href="mailto:me@edwinariza.com">Write a Email.</a>
      </h3>
      <a href="/" class="button is-primary mt-5">Return to Home</a>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from "@/components/Footer/FooterComponent.vue";

export default {
  name: 'PaymentsSuccessView',
  components:{
    FooterComponent
  },
  data() {
    return {
      productSelected:false,
      item:{}
    }
  },
  methods:{
    detectItem: function (){
      let itemTemp = localStorage.getItem('product_payment_selected');
      console.log(itemTemp)
      if(itemTemp !== null && itemTemp !== ''){
        this.productSelected = true;
        this.item = JSON.parse(itemTemp);
      }
      else{
        this.productSelected = false;
      }
    },
    cancelTransaction: function(){
      localStorage.removeItem('product_payment_selected');
      location.href="/payments?cancel"
    }
  },
  beforeMount() {
    this.detectItem()
  }
}
</script>
<style scoped lang="scss">
  .content{
    height: 70vh;
    padding-top: 7em;
    text-align: center;
  }
  h1{
    font-size: 2.5em;
  }
  .message_success{
    font-size: 2.0em;
  }
</style>