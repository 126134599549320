<template>
  <section class="brief" id="startups">
    <h3>Each day is an opportunity to improve.</h3>
    <h4>
      Develop your skills, improve your business and your life to grow.
    </h4>
  </section>
</template>
<script>
  export default {
    name: 'ExperienceComponent',
  }
</script>
<style lang="scss" scoped>
  .brief{
    background-color: #333333;
    text-align: center;
    padding:3em;
    /**border-radius: 1em;
    margin:1em;**/
    h2{
      font-size: 2.2em;
      color: #fefefe;
      font-weight: bold;
    }
    h3{
      font-size: 1.6em;
      color: #fefefe;
      font-weight: bold;
    }
    h4{
      font-size:1.3em;
      color:#dddddd;
      margin-right: 15%;
      margin-left: 15%;
    }
  }
</style>