<template>
    <section class="skills">
      <div class="skills-content">
        <div v-for="skill in skills" v-bind:key="skill.id" class="border-bottom mb-1 pb-2">
          <b>{{skill.name}}</b>: <small>{{skill.description}}</small>
          <b-progress type="is-primary" :rounded="false" :value="skill.percent" show-value format="percent" ></b-progress>
          </div>
      </div>
    </section>
</template>

<script>
    import axios from "axios";

    export default {
        name: 'SkillsComponent',
        data: function(){
          return {
            skills:[]
          }
        },
      methods: {
        getSkill: function(){
          axios.get('https://edwinariza.com/skills/get').then(response => {
            this.skills = response.data;
          });
        },
      },
      mounted() {
        this.getSkill()
      }
    };
</script>

<style lang="scss" scoped>
  @import "../../assets/main.scss";
  .skills{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .skills-content{
      max-width: $page_content_width;
      width: 100%;
      .title{
        font-size: 2em;
        text-align: center;
      }
    }
  }

</style>