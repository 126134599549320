<template>
  <section class="p-3 has-background-grey-lighter" style="min-height: 70vh" >
    <h2 class="has-text-centered mt-5 is-bold">Secure Payments with Paypal</h2>
    <div class="columns">
      <div class="column is-one-third">
        <!-- NOTHING RIGHT NOW -->
      </div>
      <div class="column is-one-third">
        <!-- CHECKOUT -->
        <div class="card has-background-warning-light">
          <div class="card-content">
            <div class="title is-bold" v-html="$props.itemSelected.name"></div>
            <div class="description" v-html="$props.itemSelected.description"></div>
            <div class="has-text-right mt-3 ">
              <b style="font-size: 2.5em;">${{$props.itemSelected.value}}</b> {{$props.itemSelected.currency}}
            </div>
            <div class="has-text-centered is-bold mt-3"><small>Secure payments <b>with PayPal</b></small></div>
          </div>
        </div>
        <!-- PAYPAL BUTTONS -->
        <div class="card border mt-3">
          <div class="card-content ">
            <div v-if="!paid" id="paypal-button-container"></div>
            <div v-else id="confirmation">Order complete!</div>
          </div>
        </div>

      </div>
      <div class="column is-one-third">
        <!-- NOTHING RIGHT NOW -->
      </div>
    </div>
  </section>
</template>

<script>
import { loadScript } from '@paypal/paypal-js';
export default {
  name: 'PaypalComponent',
  mounted: function() {
    loadScript({'client-id': CLIENT_ID, 'currency': this.currency }).then((paypal) => {
      paypal.Buttons({
          createOrder: this.createOrder,
          onApprove: this.onApprove,
          style: {
                layout:  'vertical',
                color:   'blue',
                shape:   'rect',
                label:   'paypal'
              }
        }).render('#paypal-button-container');
    });
  },
  data() {
    return {
      paid: false
    };
  },
  props: {
    itemSelected: {},
    cartTotal: {
      type: Number,
      default: 0.01,
    },
    currency: {
      type: String,
      default:"USD"
    }
  },
  methods: {
    createOrder: function(data, actions) {
      console.log('Creating order...');
      console.log(this.cartTotal);
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: this.cartTotal
            },
          },
        ],
      });
    },
    onApprove: function(data, actions) {
      console.log('Order approved...');
      return actions.order.capture().then(() => {
        this.paid = true;
        console.log('Order complete!');
        location.href = "/payments/success"
      });
    }
  },
};
// PRODUCTION
const CLIENT_ID = 'AR7v_26dyxYXVRnHEW9meXIsEFNV7JwXxrncNci5UnmHq9RWhvK_nkCVUSQRKzo2gUZv9z9VoeLNutF4';
// TEST
// const CLIENT_ID = 'Aa5mlI9MysYBNlzSrv2aWSPqEaSsDU70yJrIZvNIJcUPiHi31NTLp0QEW3fV0g86B0nDv5qjmVGfOf1I';
</script>

<style scoped>

h2{
  font-size: 2.0em;
  font-weight: bold;
}

#paypal-button-container {
  margin: 30px 0;
}

#confirmation {
  color: green;
  margin-top: 1em;
  font-size: 2em;
}
</style>