<template>
  <div id="projects"  class="container is-rounded is-dark">
    <h2>{{$props.title}}</h2>
    <div class="columns">
      <div class="column is-one-quarter" v-for="project in $props.projects" :key="project.id" >
        <div class="card">
        <div class="card-content">
          <div class="media ">
            <div class="media-left">
              <figure class="image is-48x48">
                <img :src="project.icon" alt="Placeholder image">
              </figure>
            </div>
            <div class="media-content ">
              <p class="title is-4">{{project.name}}</p>
              <p class="subtitle is-6">by <b>@edwin_ariza</b></p>
            </div>
          </div>

          <div class="content">
            {{project.description}}
            <b-taglist class="border-top mt-5">
                <b-tag v-for="tag in JSON.parse(project.tags)" :key="tag.id" type="is-primary" size="is_medium">
                  {{tag.name}}
                </b-tag>
            </b-taglist>
          </div>
        </div>
        <footer class="card-footer p-2">
          <a :href="project.url" target="_blank" class="button is-dark card-footer-item">Try it!</a>
        </footer>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ProjectsComponent',
    props: ['title', 'projects'],
    setup(props) {
      console.log(props)
    },
  }
</script>
<style scoped>
  .container{
    min-height: fit-content;
    max-height: fit-content;
    padding: 1em;
  }
  h2{
    font-size: 2em;
    font-weight: bold;
    text-align: center;
  }
  p{
    font-size: medium;
  }

</style>