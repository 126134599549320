<template>
  <section  class="files_component pt-5 pb-5">
    <h2 v-html="$props.title"></h2>
    <p class="has-text-centered" style="font-size: 2em" v-html="$props.subtitle"></p>
    <div class="columns mt-5 is-multiline ">
      <div class="column is-4" v-for="item in items" :key="item.id">
        <article class="media">
          <div class="media-left">
            <figure class="image is-64x64">
              <a target="_blank" :href="item.url">
                <img :src="require('@/assets/'+item.image)" :alt="item.name">
              </a>
            </figure>
          </div>
          <div class="media-content">
            <div class="content">
              <p>
                <a target="_blank" :href="item.url"><strong>{{item.name}}</strong></a> <b-tag type="is-info">{{item.type}}</b-tag>
                <br>
                {{item.description}}
              </p>
            </div>
          </div>
        </article>
      </div>

    </div>
  </section>
</template>
<script>
  export default {
    name: 'FilesComponent',
    props: ['title', 'subtitle', 'items'],
    setup(props) {
      console.log(props)
    }
  }
</script>
<style scoped>
  .files_component {
    min-height: fit-content;
    max-height: fit-content;
    padding: 1em;
    border-bottom: 2px solid #222222;
  }
  h2 {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
  }
  p {
    font-size: medium;
  }
</style>