<template>
  <section v-if="visible" id="code" class="container-blog">
    <div class="ml-2">
      <h2>Github Repositories (Components, modules and libraries)</h2>
    </div>
    <div class="columns is-multiline " id="posts">
      <div class="column is-one-quarter" v-for="item in items" v-bind:key="item.id">
        <PostGithubComponent
            :name="item.name"
            :description="item.description"
            :image="'https://edwinariza.com/'+item.image"
            :author="item.organization"
            :url="item.url"
        />
      </div>
      <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </section>
</template>

<script>
import PostGithubComponent from "@/components/Posts/PostGithubComponent.vue";
import axios from 'axios';
export default {
  name: "PostsGithubComponent",
  components: {
    PostGithubComponent
  },
  data:function () {
    return{
      isLoading:true,
      isFullPage:true,
      items:[],
      visible:true
    }
  },
  methods: {
    getUserRepos: function(){
      axios.get('/github/get_user_repos').then(response => {
        this.items = response.data;
        this.isLoading = false;
      }).catch((error) => {
        console.log(error)
        this.visible=false
        this.isLoading=false
      });
    },
    getOrganizationRepos: function(){
      axios.get('/github/get_organization_repos').then(response => {
        this.items = response.data;
        this.isLoading = false;
      }).catch((error) => {
        console.log(error)
        this.visible=false
        this.isLoading=false
      })
    }
  },
  mounted() {
    // CARGAMOS LOS ARTICULOS
    //this.getUserRepos()
    this.getUserRepos()
  }
}
</script>

<style scoped>
  .container-blog{
    border-bottom: 1px solid #aaaaaa;
    background-color: rgb(11, 28, 152);
    border-top: 3px solid #fefefe;
    padding-bottom: 2em;
    padding-top: 2em;
  }
  h2{
    font-size: 2.0em;
    color: #fefefe;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1em;
  }
</style>