<template>
  <section id="code" class="container-blog p-2">
    <div class="ml-2">
      <h2>Areas of research</h2>
    </div>
    <div class="columns is-multiline " id="posts">
      <div class="column is-one-quarter" v-for="project in projects" v-bind:key="project.id">
        <PostResearchComponent
            :name="project.name"
            :description="project.description"
            :author="project.author"
            :image="project.image"
            :url="project.url"
        />
      </div>
      <!--<b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false"></b-loading>-->
    </div>
  </section>
</template>

<script>
import PostResearchComponent from "@/components/Posts/PostResearchComponent.vue";
export default {
  name: "PostsResearchComponent",
  components: {
    PostResearchComponent
  },
  data:function () {
    return{
      isLoading:true,
      isFullPage:true,
      projects:[
        { name: "Aquaponics System",
          description: "Using  technologies to improve the production of fishes and vegetables in minimal areas of sowing.",
          author: "Edwin Ariza",
          image: "https://images.pexels.com/photos/348689/pexels-photo-348689.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
          url: "/aquaponics_system"
        }
      ]
    }
  },
  methods: {

  },
  mounted() {
    // CARGAMOS LOS ARTICULOS
  }
}
</script>

<style scoped>
  .container-blog{
    border-bottom: 1px solid #aaaaaa;
    background-color: #fefefe;
    border-top: 3px solid #fefefe;
    padding-bottom: 2em;
    padding-top: 2em;
  }
  h2{
    font-size: 2.0em;
    color: #222222;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1em;
  }
</style>