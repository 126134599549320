<template>
  <div  class="container is-rounded">
    <h2 v-html="$props.title"></h2>
    <p class="has-text-centered" style="font-size: 2em" v-html="$props.content"></p>
  </div>
</template>
<script>
  export default {
    name: 'ContentComponent',
    props: ['title', 'content'],
    setup(props) {
      console.log(props)
    }
  }
</script>
<style scoped>
  .container{
    min-height: fit-content;
    max-height: fit-content;
    padding: 1em;
  }
  h2{
    font-size: 2em;
    font-weight: bold;
    text-align: center;
  }
  p{
    font-size: medium;
  }

</style>