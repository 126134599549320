<template>
  <section class="steps p-4 bg-light" id="ideatobis">
    <div class="steps-content">
      <h2 class="has-text-centered">Apply with me a simple methodology to transform your business</h2>
          <h3 class="has-text-centered"><b>Understand your journey, accept your situation and work.</b><br>A pathway for your business is:</h3>
      <div class="columns">
        <div class="column is-three-quarters">
          <b-steps
              v-model="activeStep"
              :animated="isAnimated"
              :rounded="isRounded"
              :has-navigation="hasNavigation"
              :label-position="labelPosition"
              :mobile-mode="mobileMode"
              class="is-dark"
              animateInitially="true"
              style="border-radius:1em;padding:2em;"
          >

            <!--STEP 1-->
            <b-step-item step="1" label="Idea Definition" type="dark" :clickable="isStepsClickable">
              <ContentComponent
                  title="99% of your  ideas are immature or non-viable."
                  content="Learn about your market, talk with customers, eval your idea and improve." />
                  <template>
                    <div class="has-text-centered">
                      <p>
                        Use our solution to manage ideas and transform into business.
                      </p>
                      <a target="_blank" class="button is-warning mt-2 is-rounded" style="margin: auto;" href="https://wydealab.com">Start with &nbsp;<b>WydeaLab</b></a>
                    </div>
                  </template>
            </b-step-item>

            <!--STEP 1-->
            <b-step-item step="2" label="Business Model" :clickable="isStepsClickable">
              <ContentComponent
                  title="Define business with focus and simple process."
                  content="A real business model require 1000 versions to be successful just start simple to grow agile." />
                  <template>
                    <div class="has-text-centered">
                      <p>
                        Create your business model and review with our platform.
                      </p>
                      <a target="_blank" class="button is-warning mt-2 is-rounded" style="margin: auto;" href="https://wydealab.com">Start with &nbsp;<b>WydeaLab</b></a>
                    </div>
                  </template>
            </b-step-item>

            <!-- STEP 2 -->
            <b-step-item step="3" label="Research" :clickable="isStepsClickable">
              <ContentComponent
                  title="Research is awesome to innovate."
                  content="Investigate, read, post and define knowledge to share and improve the humanity life." />
                  <template>
                    <div class="has-text-centered">
                      <p>
                        Find resources, scholarships, capital and help with our platform and develop prototypes.
                      </p>
                      <a target="_blank" class="button is-warning mt-2 is-rounded" style="margin: auto;" href="https://wydealab.com/scrapidt/">Start with &nbsp;<b>Scrapidt</b></a>
                      <a target="_blank" class="button is-primary mt-2 ml-5 is-rounded" style="margin: auto;" href="https://systemico.net">Start with &nbsp;<b>Systemico Technologies</b></a>
                    </div>
                  </template>
            </b-step-item>

            <b-step-item step="4" label="Development" :clickable="isStepsClickable">
              <ContentComponent
                  title="Develop software and transform your ideas in reality."
                  content="The better in life is have users to try your software and get feedback." />
                  <template>
                    <div class="has-text-centered">
                      <p>
                        Develop your software with an expert company, please contact to <b>Systemico</b> or improve your tech team with a <b>Jumichica</b> Tooling Team.
                      </p>
                      <a target="_blank" class="button is-primary mt-2 is-rounded" style="margin: auto;" href="https://systemico.net">Start with &nbsp;<b>Systemico Technologies</b></a>
                      <a target="_blank" class="button is-danger mt-2 ml-5 is-rounded" style="margin: auto;" href="https://jumichica.com">Start with &nbsp;<b>Jumichica</b>&nbsp; Tooling Team</a>
                    </div>
                  </template>
            </b-step-item>

            <b-step-item step="5"  label="Release" :clickable="isStepsClickable" >
              <ContentComponent
                  title="Release quickly and test your solution."
                  content="You need a market fit and regular customers, each version is a new opportunity to reach the goal." />
                  <template>
                    <div class="has-text-centered">
                      <p>
                        Release your solution on cloud or on-premise with:
                      </p>
                      <a target="_blank" class="button is-primary mt-2 is-rounded" style="margin: auto;" href="https://systemico.net">Start with &nbsp;<b>Systemico Technologies</b></a>
                    </div>
                  </template>
            </b-step-item>

            <b-step-item step="6"  label="Try Again" :clickable="isStepsClickable" >
              <ContentComponent
                  title="Breathe and think better."
                  content="Create a business is not easy, be resilient." />
            </b-step-item>
            <!--<template
                v-if="customNavigation"
                #navigation="{previous, next}">
                <b-button
                    outlined
                    type="is-danger"
                    icon-pack="fas"
                    icon-left="backward"
                    :disabled="previous.disabled"
                    @click.prevent="previous.action">
                    Previous
                </b-button>
                <b-button
                    outlined
                    type="is-success"
                    icon-pack="fas"
                    icon-right="forward"
                    :disabled="next.disabled"
                    @click.prevent="next.action">
                    Next
                </b-button>
            </template>-->
          </b-steps>
        </div>
        <div>
          <img src="@/assets/methodology.svg" alt="Consultant" style="width: 100%;"/>
        </div>
      </div>

    </div>
  </section>
</template>

<script>

import ContentComponent from "@/components/Content/ContentComponent.vue";

export default {
  name: "StepsComponent",
  components: {ContentComponent},
  data() {
    return {
      activeStep: 0,
      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,

      hasNavigation: false,
      customNavigation: false,
      isProfileSuccess: false,

      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'compact'
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../assets/main.scss";
  .steps{
    display: flex;
    justify-content: center;
    align-items: center;
    .steps-content{
       max-width: $page_content_width;
      h2{
        font-size: 2.0em;
        padding-bottom: 0.5em;
        font-weight: bold;
        color: #222222;
      }
      h3{
        font-size: 1.6em;
        padding-bottom: 1em;
      }
    }
  }

</style>