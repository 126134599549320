<template>
  <div class="post card m-1">
    <div class="card-image is-hidden">
      <figure class="image is-4by3"  style="background:#00d4ff;border-bottom: 3px solid #00d4ff;">
        <img :src="$props.image" alt="Placeholder image">
      </figure>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <div class="title">
            <a :href="$props.url">{{$props.title}}</a>
          </div>
          <div class="subtitle mt-2">{{$props.author}}</div>
        </div>
      </div>

      <div class="content">
        <div v-html="$props.content"></div>
        <time :datetime="$props.date">{{$props.date}}</time>
      </div>
    </div>
    <footer class="card-footer">
      <!--
      <a href="#" class="card-footer-item">

      </a>-->
      <a target="_blank" :href="'https://www.facebook.com/sharer/sharer.php?u='+$props.url" class="card-footer-item">
        <svg-icon type="mdi" :path="facebook"></svg-icon>
      </a>
      <a target="_blank" :href="'https://twitter.com/intent/tweet?text='+$props.title+'&url='+$props.url" class="card-footer-item">
        <svg-icon type="mdi" :path="twitter"></svg-icon>
      </a>
      <a target="_blank" :href="'whatsapp://send?text='+$props.url" class="card-footer-item">
        <svg-icon type="mdi" :path="whatsapp"></svg-icon>
      </a>
      <a target="_blank" :href="'https://mastodon.social/?text='+$props.title+'&url='+$props.url" class="card-footer-item">
        <svg-icon type="mdi" :path="mastodon"></svg-icon>
      </a>
      <a target="_blank" :href="'https://www.linkedin.com/sharing/share-offsite/?url='+$props.url" class="card-footer-item">
        <svg-icon type="mdi" :path="linkedin"></svg-icon>
      </a>

    </footer>
  </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiLinkedin, mdiTwitter, mdiWhatsapp, mdiFacebook, mdiMastodon } from '@mdi/js';
export default {
  name: "PostComponent",
  props: ['title', 'content', 'image', 'date', 'author', 'url'],
  setup(props) {
    console.log(props)
  },
  components:{
    SvgIcon
  },
  data() {
    return {
      linkedin: mdiLinkedin,
      twitter: mdiTwitter,
      whatsapp: mdiWhatsapp,
      facebook: mdiFacebook,
      mastodon: mdiMastodon
    }
  }
}
</script>

<style lang="scss" scoped>
  .post{
    .title{
      padding:0;
      margin:0;
      font-size: 1.5rem;
      a {
        color: #222222;
      }
      a:hover {
        color: #0B1C98;
      }
    }
    .subtitle{
      padding:0;
      margin:0;
      font-size: 1em;
    }
    .content{
      padding:0;
      margin:0;
      font-size: 0.8em;
    }
    footer {
      a {
        color: #222222;
      }
      a:hover {
        color: #0B1C98;
      }
    }
  }
</style>