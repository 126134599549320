<template>
  <div>
    <div class="Footer has-background-black-bis p-5">
        <div class="columns">
          <div class="column" id="contact">
            <img src="@/assets/logo.png" width="200px"/>
            <div style="color: #fefefe;font-weight: bold;">Contacto:</div>
            <div class="has-text-white">{{email}}</div>
            <div class="has-text-white mb-4">{{phone}}</div>
            <!-- SOCIAL NETWORKS -->
            <a target="_blank" href="https://twitter.com/edwin_ariza" class="item_social_network">
              <svg-icon type="mdi" :path="twitter"></svg-icon>
            </a>
            <a target="_blank" href="https://www.linkedin.com/in/edwinariza/" class="item_social_network">
              <svg-icon type="mdi" :path="linkedin"></svg-icon>
            </a>
            <a target="_blank" href="https://www.facebook.com/edwinarizapro/" class="item_social_network">
              <svg-icon type="mdi" :path="facebook"></svg-icon>
            </a>
            <a target="_blank" href="https://wa.me/573186195270?text=Hey Edwin" class="item_social_network">
              <svg-icon type="mdi" :path="whatsapp"></svg-icon>
            </a>
            <a target="_blank" href="https://mastodon.social/@edwin_ariza" class="item_social_network">
              <svg-icon type="mdi" :path="mastodon"></svg-icon>
            </a>
          </div>
          <div class="column" v-for="menu in menus" v-bind:key="menu.id">
            <ul>
              <li class="main">{{menu.name}}</li>
              <li v-for="link in menu.links" v-bind:key="link.id">
                <a :href="link.ulr" :title="link.tooltip" >{{link.label}}</a>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <div class="signature has-text-white p-2 has-text-centered">
      <b>{{name}}</b> - {{slogan}}
    </div>
  </div>
</template>

<script>
    import SvgIcon from "@jamescoyle/vue-icon";
    import { mdiLinkedin, mdiTwitter, mdiWhatsapp, mdiFacebook, mdiMastodon } from '@mdi/js';
    export default {
      name: 'FooterComponent',
      components: {SvgIcon},
      data: function (){
        return{
          name:"Edwin Ariza",
          email: "me@edwinariza.com",
          phone: "+1 314 668‑1412",
          slogan: "Simple solutions to common problems.",
          menus:[
            {
              id:1,
              name: "Sections",
              links:
              [
                { id:1, label:"IT Project Manager", ulr:"/project_manager", tooltip:"IT Project Manager" },
                { id:2, label:"Agritech Researcher", ulr:"/researcher", tooltip:"Agritech Researcher" },
                { id:3, label:"Senior Software Developer", ulr:"/developer", tooltip:"Senior Software Developer Profile" },
                { id:4, label:"Project Formulator", ulr:"/formulator", tooltip:"Project Formulator" }
              ]
            },
            {
              id:2,
              name: "Software Portfolio",
              links:
              [
                { id:1, label:"Wabot", ulr:"https://wabot.systemico.net", tooltip:"Notifications and bot for WhatsApp, Telegram, and more." },
                { id:2, label:"Suppmail", ulr:"https://suppmail.systemico.net", tooltip:"Support and complaints platform." },
                { id:3, label:"Grumpyturtle", ulr:"https://grumpyturtle.systemico.net", tooltip:"Project management platform." },
                { id:4, label:"WydeaLab", ulr:"https://wydealab.com", tooltip:"Integrated platform to manages your innovation process." },
                { id:5, label:"Scrapidt", ulr:"https://scrapidt.wydealab.com", tooltip:"Identify grants, subventions to improve your startup." }
              ]
            },
            {
              id:3,
              name: "Startups",
              links:
              [
                { id:1, label:"Systemico - software for small business", ulr:"https://systemico.net", tooltip:"Systemico Technologies" },
                { id:2, label:"WydeaLab - Innovation platform", ulr:"https://wydealab.com", tooltip:"WydeaLab" },
                { id:3, label:"Jumichica - Tooling team", ulr:"https://jumichica.com", tooltip:"Jumichica" },
                /**{ id:4, label:"Eteamer", ulr:"https://eteamer.net", tooltip:"Eteamer" },
                { id:1, label:"Midder", ulr:"", tooltip:"" },
                { id:1, label:"Legalet", ulr:"", tooltip:"" },**/
              ]
            }
          ],
          linkedin: mdiLinkedin,
          twitter: mdiTwitter,
          whatsapp: mdiWhatsapp,
          facebook: mdiFacebook,
          mastodon: mdiMastodon
        }
      }
    };
</script>

<style lang="scss" scoped>
  .Footer{
    /**border-radius: 1em;
    margin:1em;**/
  }
  li a{
    color:#fefefe;
  }
  .main{
    color: #fefefe;
    font-weight: bold;
    font-size: 1.2em;
  }
  .item_social_network{
    /**border:2px solid #fefefe;**/
    padding-left:3px;
    padding-right:3px;
    padding-top: 10px;
    height: 20px;
    width: 20px;
    /**background-color: #dddddd;**/
    /**border-radius: 5px;**/
    margin-top:10px;
    margin-right:5px;
  }
  .signature{
    background-color: #000000;
  }
</style>