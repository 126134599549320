<template>
  <div>
    <!-- PAYPAL FORM -->
    <PaypalComponent v-if="productSelected" :itemSelected="item" :cartTotal="item.value" :currency="item.currency"/>
    <ProductsOptionsComponent v-else/>
    <div class="has-text-centered has-background-grey-lighter" v-if="productSelected">
      <div class="mb-3"><b>Do you want to cancel the transaction?</b>, No problem, just press this button:</div>
      <a class="button is-danger mb-3" href="/payments#cancel" @click="cancelTransaction">
        Cancel Transaction
      </a>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from "@/components/Footer/FooterComponent.vue";
import PaypalComponent from "@/components/Paypal/PaypalComponent.vue";
import ProductsOptionsComponent from "@/components/Paypal/ProductsOptionsComponent.vue";
export default {
  name: 'PaymentsView',
  components:{
    ProductsOptionsComponent,
    PaypalComponent,
    FooterComponent
  },
  data() {
    return {
      productSelected:false,
      item:{}
    }
  },
  methods:{
    detectItem: function (){
      let itemTemp = localStorage.getItem('product_payment_selected');
      console.log(itemTemp)
      if(itemTemp !== null && itemTemp !== ''){
        this.productSelected = true;
        this.item = JSON.parse(itemTemp);
      }
      else{
        this.productSelected = false;
      }
    },
    cancelTransaction: function(){
      localStorage.removeItem('product_payment_selected');
      location.href="/payments?cancel"
    }
  },
  beforeMount() {
    this.detectItem()
  }
}
</script>
