<template>
  <section class="products p-5 has-background-primary-light">
    <div class="products-content">
      <h2 class="has-text-centered mb-3 is-bold">Know my services</h2>
      <!--<h3 class="has-text-centered mb-5">Just one step to complete your sale.</h3>-->
      <div class="columns w-100">
        <div class="column is-one-quarter" style="margin:auto;" v-for="item in items" v-bind:key="item.id">
          <div class="card" style="min-height: 22em">
            <div class="card-image">
              <figure class="image is-4by3">
                <img :src="'https://edwinariza.com/static/headers/products/header-'+item.id+'.svg'" alt="Placeholder image">
              </figure>
            </div>
            <div class="card-content">
              <div class="title is-bold" style="min-height: 2em" v-html="item.name"></div>
              <div class="description" style="min-height: 8em" v-html="item.description"></div>
              <div class="has-text-right mt-3">
                <!--<b-button size="is-small" type="is-primary" @click="selectItem(item)" rounded>
                  Learn more
                </b-button>-->
                <b-button size="is-small" type="is-primary" @click="selectItem(item)" rounded>
                  from &nbsp;<b>${{item.value}}</b> &nbsp;{{item.currency}}
                </b-button>
              </div>
              <div class="has-text-centered mt-3" style="font-size: 0.8em;color: #777777;">Secure payments <b>with PayPal</b></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProductsOptionsComponent",
  components: {

  },
  data() {
    return {
      items:[

      ]
    };
  },
  methods: {
    selectItem:function (itemSelected){
      localStorage.setItem('product_payment_selected', JSON.stringify(itemSelected))
      location.href="/payments?item="+itemSelected.id
    }
  },
  beforeMount() {
    fetch('https://edwinariza.com/static/payments/products.json')
      .then(r => r.json())
      .then(json_products => {
        this.items=json_products;
      });
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss";
.products{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fefefe;
  .products-content{
    max-width: $page_content_width;
    margin:auto;
    h2{
      font-size: 2.0em;
      font-weight: bold;
    }
    h3{
      font-size: 1.8em;
    }
    .title{
      font-size: 1.5em;
      font-weight: bold;
    }
  }
}

</style>

