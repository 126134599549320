<template>
  <section class="time_box_view">
    <div class="columns">
      <div class="column">
        <h1>The <br>Time<br> Box.</h1>
        <h2>3 Top Priorities.</h2>
        <div v-for="priority in priorities" :key="priority.id">
          <b-field :label="priority.name" horizontal>
            <b-input class="entry" v-model="priority.description" :placeholder="priority.placeholder"></b-input>
          </b-field>
        </div>
        <h2>Brain Dump.</h2>
        <b-field label="Message" horizontal>
            <b-input class="entry" v-model="brain_dump.description" :placeholder="brain_dump.placeholder" maxlength="200" type="textarea"></b-input>
        </b-field>
      </div>
      <div class="column">
        <h2>Tasks.</h2>
        <div v-for="task in tasks" :key="task.id">
          <b-field :label="task.id+':00'" horizontal>
            <b-input class="entry" v-model="task.description" :placeholder="task.placeholder" ></b-input>
          </b-field>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
  name: 'TimeBoxView',
  data() {
    return {
      priorities:[
        {id:1, name:'Priority 1', description:'', placeholder:'Define the first priority.'},
        {id:2, name:'Priority 2', description:'', placeholder:'Define the second priority.'},
        {id:3, name:'Priority 3', description:'', placeholder:'Define the third priority.'}
      ],
      tasks:[
        {id:0, name:'Task 0', description:'', placeholder:'Define the task.'},
        {id:1, name:'Task 1', description:'', placeholder:'Define the task.'},
        {id:2, name:'Task 2', description:'', placeholder: 'Define the task.'},
        {id:3, name:'Task 3', description:'', placeholder: 'Define the task.'},
        {id:4, name:'Task 4', description:'', placeholder: 'Define the task.'},
        {id:5, name:'Task 5', description:'', placeholder: 'Define the task.'},
        {id:6, name:'Task 6', description:'', placeholder: 'Define the task.'},
        {id:7, name:'Task 7', description:'', placeholder: 'Define the task.'},
        {id:8, name:'Task 8', description:'', placeholder: 'Define the task.'},
        {id:9, name:'Task 9', description:'', placeholder: 'Define the task.'},
        {id:10, name:'Task 10', description:'', placeholder: 'Define the task.'},
        {id:11, name:'Task 11', description:'', placeholder: 'Define the task.'},
        {id:12, name:'Task 12', description:'', placeholder: 'Define the task.'},
        {id:13, name:'Task 13', description:'', placeholder: 'Define the task.'},
        {id:14, name:'Task 14', description:'', placeholder: 'Define the task.'},
        {id:15, name:'Task 15', description:'', placeholder: 'Define the task.'},
        {id:16, name:'Task 16', description:'', placeholder: 'Define the task.'},
        {id:17, name:'Task 17', description:'', placeholder: 'Define the task.'},
        {id:18, name:'Task 18', description:'', placeholder: 'Define the task.'},
        {id:19, name:'Task 19', description:'', placeholder: 'Define the task.'},
        {id:20, name:'Task 20', description:'', placeholder: 'Define the task.'},
        {id:21, name:'Task 21', description:'', placeholder: 'Define the task.'},
        {id:22, name:'Task 22', description:'', placeholder: 'Define the task.'},
        {id:23, name:'Task 23', description:'', placeholder: 'Define the task.'}
      ],
      brain_dump: {
        description:'',
        placeholder:'Write down your thoughts.'
      }
    }
  },
  components:{
  }
}
</script>
<style scoped lang="scss">
  .time_box_view{
    min-height: 70vh;
    padding-top: 0rem;
    background: #dddddd;
    margin:1rem;
    padding:1rem;
    border:2px solid #000;
    border-radius: 1rem;
    h1{
      font-size: 2.8rem;
      line-height: 2.4rem;
      font-weight: bold;
      margin-left: 1.8rem;
      color:#000000;
    }
    h2{
      font-size: 1.5rem;
      font-weight: bolder;
      text-align: right;
      color:#0B1C98;
    }
    .entry{
      font-size: 1rem;
      margin-bottom: 0.2rem;
    }
  }
</style>