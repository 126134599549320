<template>
  <main>
    <BannerComponent title="Formulador de proyectos de innovación."
                     subtitle="Formulación de proyectos para transporte, logística, consultoras, startups y agronegocios."
                     :image="require('@/assets/project_formulator.svg')"
                     background="#f8f8f8"
                     color="#222222"
    />
    <BriefComponent :content="brief['es']" lang="es" @open-modal-contact="$emit('open-modal-contact')" />
    <CertificationsComponent />
    <ActivityTimeLine title="Metodología de trabajo"
                      subtitle="Conocer los pasos para formular un proyecto éxitos es la clave para enfocar tu trabajo." />
    <ProjectsComponent title="Formulated projects" :projects="projects" />
    <FaqComponent />
    <AdsComponent
        title="<b>WydeaLab</b>, una solución para simplificar la formulación de proyectos."
        subtitle="Transforma ideas en proyectos de impacto."
        note="I participated like a <b>project manager of wydealab platform</b> the software linked to this recommendation."
        :tags="tags"
        background="#FFC733"
        image="ads/image_2.svg"
        url="https://wydealab.com"
    />
    <PostsComponent />
  </main>
</template>

<script>
  import axios from "axios";
  import ActivityTimeLine from "@/components/Steps/ActivityTimeLine.vue";
  import BannerComponent from "@/components/Banner/BannerComponent.vue";
  import BriefComponent from "@/components/Intro/BriefComponent.vue";
  import PostsComponent from "@/components/Posts/PostsComponent.vue";
  import ProjectsComponent from "@/components/Content/ProjectsComponent.vue";
  import CertificationsComponent from "@/components/Content/CertificationsComponent.vue";
  import FaqComponent from "@/components/Faq/FaqComponent.vue";
  import AdsComponent from "@/components/Banner/AdsComponent.vue";
  export default {
  name: 'FormulatorView',
  components:{
    AdsComponent,
    FaqComponent,
    CertificationsComponent,
    ProjectsComponent,
    PostsComponent,
    BriefComponent,
    BannerComponent,
    ActivityTimeLine
  },
  data(){
    return {
      tags: [
        {id:1, name:"recomendación", type: "dark"},
        {id:2, name:"software", type: "white"}
      ],
      brief: {
        es: 'Cuenta con un experto en actividades de planeación, coordinación y dirección de proyectos de innovación y tecnología, para establecer la ejecución oportuna de recursos y la culminación éxitosa de las metas.',
        en: 'Innovation and technology project formulation to detail the potential and mitigate the risks included in ' +
            'a project to protect your investment and dream. '
      },
      faq:{

      },
      projects: []
    }
  },
  methods: {
    getProjects: function(){
      axios.get('https://edwinariza.com/api/projects/get_projects').then(response => {
        this.projects = response.data;
        this.isLoading = false;
      });
    },
  },
  beforeMount() {
    // CARGAMOS LOS ARTICULOS
    this.getProjects()
  },
  metaInfo: {
    title: 'Edwin Ariza: Formulador de proyectos de innovación y tecnología.',
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Formulador experto para de proyectos de innovación y tecnología para salud, cooperativas, transporte, logística, consultoras, startups y agronegocios.'}
    ],
    htmlAttrs: {
      lang: 'es'
    },
  },
}
</script>

<style scoped>
  @media (min-width: 1024px) {
    header {
      line-height: 1.5;
    }
    header {
      display: flex;
      place-items: center;
      padding-right: calc(var(--section-gap) / 2);
    }
    header .wrapper {
      display: flex;
      place-items: flex-start;
      flex-wrap: wrap;
    }

  }
</style>