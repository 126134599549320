<template>
  <section class="iam" id="iam">
    <h2>Experience like a developer</h2>
    <div class="columns mt-5">
      <div class="column col-4">
        I'm a Colombian Software Engineer, with experience in Software Development with different technologies using concepts of extensible software, reuse, libraries, frameworks and components with high experience in Python, VUE and PHP to implement nice solutions for web, desktop and cli.
      </div>
      <div class="column col-4">
        <b>I have experience in more than 100 projects</b> using continuous integration, aws, bitbucket, github, linux, scrapping and others methods and technologies.
      </div>
      <div class="column col-4">
        I participated developing and leading the development team for  projects for agritech, financial, education, logistics and other sectors, using agile and waterfall techniques using my knowledge like Python MTA Certified, Project Management Professional (PMP-PMI) and SCRUM (Developer and Product Owner).
      </div>
    </div>
    <div class="mt-2">
      <a href="https://www.linkedin.com/in/edwinariza/" title="LinkedIn" target="_blank" class="btn-linkedin btn-lg">
      <svg-icon class="pt-2" type="mdi" :path="linkedin"></svg-icon> LinkedIn Profile</a>
    </div>
  </section>
</template>
<script>
  import SvgIcon from "@jamescoyle/vue-icon";
  import { mdiLinkedin } from '@mdi/js';
  export default {
    name: 'DeveloperBriefComponent',
    components: {SvgIcon},
    data: function () {
      return {
        linkedin: mdiLinkedin
      }
    }
  }
</script>
<style lang="scss" scoped>
  .iam{
    background-color: #00d4ff;;
    border-top:3px solid #fefefe;
    text-align: center;
    padding:3em;
    h2{
      font-size: 2em;
      color: #222222;
      font-weight: bold;
    }
    h4{
      font-size:1.3em;
      color:#fefefe;
      margin-right: 15%;
      margin-left: 15%;
    }
    div{
      font-size:1.1em;
      color:#444444;
    }
    .btn-linkedin {
      background: #fefefe;
      border-radius: 0.3em;
      color: #222222;
      border-width: 1px;
      border-style: solid;
      border-color: #084461;
      padding: 10px;
    }
    .btn-linkedin:link, .btn-linkedin:visited {
      color: #222222;
    }
    .btn-linkedin:active, .btn-linkedin:hover {
      background: #fefefe;
      color: #222222;
    }
  }
</style>