<template>
  <section class="article pl-5 pr-5">
    <div class="container rounded has-background-white">
      <div class="columns">
        <div class="column is-full">
          <!--<div class="image p-0">
            <img class=" image--full" :src="'https://edwinariza.com'+image" alt="icon">
          </div>-->
          <div class="post-header">
            <h1>{{title}}</h1>
            <h4 class="author">por <b>{{author}}</b></h4>
          </div>
          <div class="post-content">
              <p v-html="content"></p>
          </div>
        </div>
      </div>
      <!-- OTHER POSTS -->
      <!--<div class="columns mt-2">
            <div class="column" v-for="post in articles_related" v-bind:key="post.id">
              <div class="card is-one-quarter rounded-0">
                <header class="card-header has-background-warning-light">
                  <p class="card-header-title">
                    Blog
                  </p>
                  <button class="card-header-icon" aria-label="more options">
                    <span class="icon">
                      <i class="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </button>
                </header>
                <div class="card-content">
                  <div class="img">
                    <img :src="post.image" alt="icon"/>
                  </div>
                  <div class="content">
                    <h4>{{ post.title }}</h4>
                    <a class="button is-light is-bordered is-small" :href="post.url"  rel="noopener noreferrer">more</a>
                  </div>
                </div>
              </div>
            </div>
        </div>-->
      </div>
  </section>
</template>


<script>
import axios from 'axios'

export default {
  name: "PostContentComponent",
  metaInfo() {
     return{
       title: this.title,
       titleTemplate: 'Edwin Ariza: %s',
       description: this.content,
       image: this.image,
       htmlAttrs: {
          lang: this.lang // TODO: CHANG TO DINAMIC
       },
       meta:[
          { charset: 'utf-8' },
          { name: 'description', content: this.content },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          { name: 'og:title', content: this.title},
          { name: 'og:image', content: this.image},
          { name: 'og:description', content: this.description},
          { name: 'og:url', content: this.url}
        ],
     }
  },

  data(){
    return {
      title: "",
      content: "",
      author: "",
      image: "",
      lang: "",
      articles_related: "",
    }
  },
  methods:{
    loadArticle(){
      let article = this.$route.params.article
      axios.post('https://edwinariza.com/posts/get', {"article": article})
      .then(response => {
        if (response.data === "None") {
          window.location.href = "/not_found"
        }
        else{
          this.title = response.data.title
          this.author = response.data.author
          this.content = response.data.content
          this.image = response.data.image
          this.lang = response.data.lang
        }
      })
      // Obtenemos el listado de artículos relacionados
      axios.get('https://edwinariza.com/posts/get_more')
      .then(response => {
        this.articles_related = response.data
      })
    }
  },
  mounted(){
    this.loadArticle();
  }
};
</script>
<style lang="scss">
  .post-header
  {
    border-bottom: 2px dotted #dddddd;
    h1 {
      font-size: 1.5em;
      font-weight: bold;
      color:#555555;
    }
  }
  .post-content {
    section {
      width: 100%;
    }
    .hashtag{
      font-size: 1rem;
      border-radius: 2rem;
      background: #dddddd;
      text-align: center;
      padding:0.5rem;
    }
    h1 {
      font-size: 2.8rem;
      line-height:3rem;
      font-weight: bold;
      width: 80%;
      margin-top:3rem;
    }

    h2 {
      font-size: 2em;
    }

    h3 {
      font-size: 1.5em;
      border-bottom: 1px dotted #dddddd;
    }
    h4 {
      font-size: 1em;
      border-bottom: 1px dotted #dddddd;
    }
    p {
      padding-top: 0em;
      padding-bottom: 0em;
      font-size: 1.2em;
    }
    ol{
      padding-left: 3rem;
      font-weight: bold;
    }
    ul {
      padding-left: 3rem;
      font-weight: bold;
    }

    li {
      padding-left: 1rem;
      font-size: 1.2em;
      font-weight: normal;
    }
    li::before {
      font-weight:bold;
    }
  }
</style>