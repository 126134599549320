<template>
  <div class="card m-1">
    <!--<div class="card-image">
      <figure class="image is-4by3">
        <img :src="$props.image" alt="Placeholder image">
      </figure>
    </div>-->
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-4">
            <a target="_blank" :href="$props.url">{{$props.name}}</a>
          </p>
          <p class="subtitle is-6">@{{$props.author}}</p>
        </div>
      </div>

      <div class="content">
        <div v-html="$props.description"></div>
      </div>
    </div>
    <footer class="card-footer">
      <!--<a href="#" class="card-footer-item">
        <span class="icon">
          <svg-icon type="mdi" :path="linkedin"></svg-icon>
        </span>
      </a>
      <a href="#" class="card-footer-item">
        <svg-icon type="mdi" :path="twitter"></svg-icon>
      </a>
      <a href="#" class="card-footer-item">
        <svg-icon type="mdi" :path="facebook"></svg-icon>
      </a>
      <a href="#" class="card-footer-item">
        <svg-icon type="mdi" :path="whatsapp"></svg-icon>
      </a>-->
      <a target="_blank" :href="'https://mastodon.social/?text='+$props.title+'&url='+$props.url" class="card-footer-item">
        <svg-icon type="mdi" :path="mastodon"></svg-icon>
      </a>
      <a target="_blank" :href="'https://www.linkedin.com/sharing/share-offsite/?url='+$props.url" class="card-footer-item">
        <svg-icon type="mdi" :path="linkedin"></svg-icon>
      </a>

    </footer>
  </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiLinkedin, mdiTwitter, mdiWhatsapp, mdiFacebook, mdiMastodon } from '@mdi/js';
export default {
  name: "PostGitHubComponent",
  props: ['name', 'description', 'url', 'author'],
  setup(props) {
    console.log(props)
  },
  components:{
    SvgIcon
  },
  data() {
    return {
      linkedin: mdiLinkedin,
      twitter: mdiTwitter,
      whatsapp: mdiWhatsapp,
      facebook: mdiFacebook,
      mastodon: mdiMastodon
    }
  }
}
</script>