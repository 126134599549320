<template>
  <div class="about">
    <BannerComponent title="Agritech Researcher"
                     subtitle="Thinking better processes, technologies and methods to prevent a global food crisis, the causes of hunger and famine."
                     :image="require('@/assets/agritech.svg')"
                     background="#fefefe"
                     color="#222222"
    />
    <BriefComponent title="Agritech, my new love and challenge."
                    subtitle="Generating technologies to improve the productivity in farms around the globe. Increase the feed production and reduce the food crisis is my motivation."
                    content="" />
    <PostsResearchComponent />
    <FilesComponent title="Formats and How-to's"
                    subtitle="Researcher's formats for different situations and needs."
                    :items="this.files"
    />
    <PostsComponent />
  </div>
</template>
<script>
  import BannerComponent from "@/components/Banner/BannerComponent.vue";
  import PostsResearchComponent from "@/components/Posts/PostsResearchComponent.vue";
  import BriefComponent from "@/components/Intro/BriefComponent.vue";
  import PostsComponent from "@/components/Posts/PostsComponent.vue";
  import FilesComponent from "@/components/Content/FilesComponent.vue";
  export default {
    name: 'ResearcherView',
    components: {FilesComponent, PostsComponent, BriefComponent, PostsResearchComponent, BannerComponent},
    metaInfo: {
      title: 'Edwin Ariza: Agritech researcher.',
      // override the parent template and just use the above title only
      titleTemplate: null
    },
    data(){
      return{
        files: [
        /**{
          id: 1,
          name: 'Requirements format.',
          description:'Define requirements clearly with the correct and required information.',
          url: '/static/files/file_1.pdf',
          image: 'icons/document.svg',
          type: 'format'
        },**/
        {
          id: 2,
          name: 'Project Charter.',
          description:'Define requirements clearly with the correct and required information.',
          url: 'https://docs.google.com/document/d/e/2PACX-1vSQvY9vu1YQKPCnmm11YK46pQjBofRSz5JH2x8M5aPeBJ_BZVoO18cx6BOfTDd2zLyCtsRuHvJaD1uo/pub',
          image: 'icons/document.svg',
          type: 'format'
        },
        /**{
          id: 3,
          name: 'Project Plan.',
          description:'Define requirements clearly with the correct and required information.',
          url: '/static/files/file_1.pdf',
          image: 'icons/document.svg',
          type: 'format'
        },
        {
          id: 4,
          name: 'User Story.',
          description:'Define requirements clearly with the correct and required information.',
          url: '/static/files/file_1.pdf',
          image: 'icons/document.svg',
          type: 'format'
        },
        {
          id: 5,
          name: 'Meeting Control.',
          description:'Define requirements clearly with the correct and required information.',
          url: '/static/files/file_1.pdf',
          image: 'icons/document.svg',
          type: 'format'
        },
        {
          id: 6,
          name: 'Changes management.',
          description:'Define requirements clearly with the correct and required information.',
          url: '/static/files/file_1.pdf',
          image: 'icons/document.svg',
          type: 'format'
        }**/
      ]
      }
    }
  }
</script>
