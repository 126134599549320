<template>
    <div class="p-2">
      <section>
        <div v-for="tip in tips" v-bind:key="tip.id" class="has-text-centered" >
          <div>
            <img :src="'https://edwinariza.com/static/fuckups/'+tip.id+'.png'" :width="$props.size" />
          </div>
        </div>
      </section>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
      name: 'TipComponent',
      data: function () {
        return {
          tips: []
        }
      },
      props: {
        size: { type: String, default: '40%' }
      },
      methods: {
        getTips: function(){
          axios.get('https://edwinariza.com/tips/get').then(response => {
            this.tips = response.data;
          });
        },
      },
      mounted() {
        this.getTips()
      }
    };
</script>

<style scoped>
.title{
  font-size: 2em;
  text-align: center;
}
</style>