<template>
  <section fluid class="web_criterias">
    <h2 class="title"> Web criterias list</h2>
    <p class="subtitle">Doing better websites.</p>
    <div v-for="(criteria, index) in web_criterias" :key="index"  class="card m-2 p-2 border">
      <h3 class="category">{{index}}</h3>
      <div v-for="(index_subcategory, subcategory) in criteria" :key="subcategory" >
        <h4 class="subcategory">{{subcategory}}</h4>
        <ul>
          <li v-for="(item_index, element) in index_subcategory" :key="element">
            <b-field>
              <b-checkbox>
                  {{item_index}}
              </b-checkbox>
            </b-field>
          </li>
        </ul>

      </div>
    </div>

  </section>
</template>

<script>
  import criterias from '../../assets/content/web_criteria.json'
  export default {
  name: 'WebCriteriasView',
  data() {
    return {
      web_criterias: criterias.websiteStructure
    }
  },
  components:{
  }
}
</script>
<style scoped lang="scss">
  .web_criterias{
    .title{
      font-size: 3rem;
      color: #222222;
    }
    .subtitle{
      font-size: 2rem;
      color: #aaaaaa;
    }
    .category{
      font-size: 2rem;
    }
    .subcategory {
      font-size: 1.5rem;
      border-bottom: 1px #aaaaaa solid;
      margin-bottom: 1rem;
    }
  }
</style>